<template>
  <b-modal
      id="modal-create-team"
      ref="modal-create-team"
      footer-class="flex-nowrap"
      no-close-on-backdrop
      centered
  >
    <template #modal-header>
      <h3>{{ $t('modals.createTeam.title') }}</h3>
    </template>

    <template #default>
      <modal-choose-team-name-and-description
          :teamName="teamName"
          :teamDescription="teamDescription"
          :namePlaceholder="$t('modals.createTeam.name')"
          :descriptionPlaceholder="$t('modals.createTeam.description')"
          @update-fields="updateFields"
          @enter-pressed="createTeam"
      ></modal-choose-team-name-and-description>
    </template>

    <template #modal-footer>
      <b-button
          class="p-3 border-right-light-grey"
          block
          @click="$emit('cancel')"
      >
        {{ $t('buttons.cancel') }}
      </b-button>
      <b-button
          :disabled="!isTeamNameSet"
          variant="primary"
          class="p-3"
          block
          @click="createTeam"
      >
        {{ $t('modals.createTeam.validate') }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'ModalCreateNewTeam',
  components: {
    ModalChooseTeamNameAndDescription: () => import('@/components/modals/ModalChooseNameAndDescription.vue'),
  },
  data: () => ({
    teamName: null,
    teamDescription: null,
  }),
  computed: {
    isTeamNameSet() {
      return !!this.teamName;
    },
  },
  mounted() {
    this.$refs['modal-create-team'].show();
  },
  methods: {
    updateFields(key, value) {
      if (key === 'name') {
        this.teamName = value.trim();
      } else if (key === 'description') {
        this.teamDescription = value.trim();
      }
    },
    async createTeam() {
      if (this.isTeamNameSet) {
        this.$emit('create-team', { name: this.teamName, desc: this.teamDescription });
        this.$refs['modal-create-team'].hide();
      }
    },
  },
};
</script>

<style scoped>

</style>
